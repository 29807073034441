import React from "react";
import PageBase from "../../components/pageBase";
import DuncanImage from "../../assets/img/photos/duncan_rowland.webp";
import JacquesImage from "../../assets/img/photos/jacques_nel.webp";
import JeffImage from "../../assets/img/photos/jeff_lloyd.webp";

import { Link } from "gatsby";

import JayImage from "../../assets/img/photos/jay_vyas.webp";
import AlyImage from "../../assets/img/photos/aly_kachra.webp";
import ReenaImage from "../../assets/img/photos/reena_chaudhary.webp";
import GregImage from "../../assets/img/photos/greg_cripps.webp";
import StuartImage from "../../assets/img/photos/stuart_turnbull.webp";
import JoshuaImage from "../../assets/img/photos/joshua_izzard.webp";
import PaulImage from "../../assets/img/photos/paul_badeski.webp";
import TonyImage from "../../assets/img/photos/tony_sevsek.webp";
import WalterImage from "../../assets/img/photos/walter_dewet.webp";
import SteveImage from "../../assets/img/photos/steve_shaw.webp";
import GabrielaImage from "../../assets/img/photos/gabriela_mataeescu.webp";
import BrianImage from "../../assets/img/photos/brian_archdekin.webp";
import TerenImage from "../../assets/img/photos/teren_teh.jpeg";

import Loadable from "@loadable/component";
const OwlCarousel = Loadable(() => import("react-owl-carousel3"));

const options = {
  loop: false,
  nav: true,
  navText: [
    '<span aria-label="Previous">‹</span>',
    '<span aria-label="Next">›</span>',
  ],
  navElement: "button",
  items: 5,
  dots: true,
  autoplayHoverPause: true,
  autoplay: false,
  responsive: {
    0: { items: 1 },
    600: { items: 3 },
    1200: { items: 5 },
  },
};

const TeamPage = () => {
  const [display, setDisplay] = React.useState(false);
  const [showMemorium, setShowMemorium] = React.useState(false);

  const handleClickMemorium = () => {
    setShowMemorium(!showMemorium);
  };

  React.useEffect(() => {
    setDisplay(true);
  }, []);

  return (
    <PageBase>
      {/* return <h1>hi</h1>; */}

      <section className="wrapper bg-light">
        <div className="container py-14 py-md-16">
          <div className="row">
            <div className="mx-auto text-center col-lg-9 col-md-10 offset-lg-2 offset-md-1">
              <h2 className="fs-15 text-uppercase text-muted mb-3">About</h2>
              <h3 className="display-4 mb-10 px-xl-10 px-xxl-15">Who We Are</h3>
            </div>
          </div>
          <div className="row">
            <div className="text-center col-lg-9 offset-lg-2">
              <p>
                The Migrations.ml solution streamlines the human workflow and
                optimizes the credit analysis process in public and private debt
                markets. Under the supervision of renowned academics and
                experienced bond traders, we train our software using large data
                sets and machine learning algorithms.
              </p>
            </div>
          </div>
          <div className="row">
            <div className="text-center col-lg-12">
              <h2 className="fs-18 text-uppercase text-line text-center mt-10 mb-3">
                Meet the Team
              </h2>
            </div>
          </div>
          <div
            className="row mb-15 team-container"
            style={{ margin: "0 auto" }}
          >
            <div className="col-lg-4">
              <div className="item">
                <div style={{ display: "flex", justifyContent: "center" }}>
                  <img
                    className="rounded-circle w-20 mx-auto mb-4"
                    src={DuncanImage}
                    alt=""
                  />
                </div>
                <h4 className="mb-1" style={{ textAlign: "center" }}>
                  Duncan Rowland
                </h4>
                <div className="meta mb-2" style={{ textAlign: "center" }}>
                  Founder & CEO
                </div>
                <p className="mb-2" style={{ textAlign: "center" }}>
                  Experienced technology executive with a deep background in
                  credit risk, trading and quantitative analysis.
                </p>
                <nav className="nav social justify-content-center text-center mb-0">
                  <a href="https://www.linkedin.com/in/duncanrowland/">
                    <i className="uil uil-linkedin"></i>
                  </a>
                </nav>
              </div>
            </div>
            <div className="col-lg-4">
              <div className="item">
                <div style={{ display: "flex", justifyContent: "center" }}>
                  <img
                    className="rounded-circle w-20 mx-auto mb-4"
                    src={JacquesImage}
                    alt=""
                  />
                </div>
                <h4 className="mb-1" style={{ textAlign: "center" }}>
                  Jacques Nel
                </h4>
                <div className="meta mb-2" style={{ textAlign: "center" }}>
                  Lead Software Engineer
                </div>
                <p className="mb-2" style={{ textAlign: "center" }}>
                  Experienced engineer with over 5 years of experience designing
                  and building high performance, data-intensive systems.
                </p>
                <nav className="nav social justify-content-center text-center mb-0">
                  <a href="https://www.linkedin.com/in/jacques-nel-5b9526b2/">
                    <i className="uil uil-linkedin"></i>
                  </a>
                </nav>
              </div>
            </div>
            <div className="col-lg-4">
              <div className="item">
                <div style={{ display: "flex", justifyContent: "center" }}>
                  <img
                    className="rounded-circle w-20 mx-auto mb-4"
                    src={TerenImage}
                    alt=""
                  />
                </div>
                <h4 className="mb-1" style={{ textAlign: "center" }}>
                  Teren Teh
                </h4>
                <div className="meta mb-2" style={{ textAlign: "center" }}>
                  Software Engineer
                </div>
                <p className="mb-2" style={{ textAlign: "center" }}>
                  Experienced engineer with product/consulting background and
                  solid track record of commercializing data products.
                </p>
                <nav className="nav social justify-content-center text-center mb-0">
                  <a href="https://www.linkedin.com/in/terenteh/">
                    <i className="uil uil-linkedin"></i>
                  </a>
                </nav>
              </div>
            </div>
          </div>
          <div className="row">
            <div className="text-center col-lg-12">
              <h2 class="fs-18 text-uppercase text-line text-center mb-3">
                Meet our Advisors
              </h2>
              {display ? (
                <OwlCarousel
                  className="article-image-slides owl-carousel owl-theme stage-center mb-18"
                  {...options}
                >
                  <div className="item">
                    <img
                      src={JayImage}
                      alt="image"
                      className="rounded-circle w-20 mx-auto mb-4"
                    />
                    <h4 className="mb-1">Jay Vyas</h4>
                  </div>

                  <div className="item">
                    <img
                      src={ReenaImage}
                      alt="image"
                      className="rounded-circle w-20 mx-auto mb-4"
                    />
                    <h4 className="mb-1">Reena Chaudhary</h4>
                  </div>

                  <div className="item">
                    <img
                      src={GregImage}
                      alt="image"
                      className="rounded-circle w-20 mx-auto mb-4"
                    />
                    <h4 className="mb-1">Greg Cripps</h4>
                  </div>

                  <div className="item">
                    <img
                      src={StuartImage}
                      alt="image"
                      className="rounded-circle w-20 mx-auto mb-4"
                    />
                    <h4 className="mb-1">Stuart Turnbull</h4>
                  </div>

                  <div className="item">
                    <img
                      src={AlyImage}
                      alt="image"
                      className="rounded-circle w-20 mx-auto mb-4"
                    />
                    <h4 className="mb-1">Aly Kachra</h4>
                  </div>

                  <div className="item">
                    <img
                      src={JoshuaImage}
                      alt="image"
                      className="rounded-circle w-20 mx-auto mb-4"
                    />
                    <h4 className="mb-1">Joshua Izzard</h4>
                  </div>

                  <div className="item">
                    <img
                      src={PaulImage}
                      alt="image"
                      className="rounded-circle w-20 mx-auto mb-4"
                    />
                    <h4 className="mb-1">Paul Badeski</h4>
                  </div>

                  <div className="item">
                    <img
                      src={TonyImage}
                      alt="image"
                      className="rounded-circle w-20 mx-auto mb-4"
                    />
                    <h4 className="mb-1">Tony Sevsek</h4>
                  </div>

                  <div className="item">
                    <img
                      src={WalterImage}
                      alt="image"
                      className="rounded-circle w-20 mx-auto mb-4"
                    />
                    <h4 className="mb-1">Walter de Wet</h4>
                  </div>

                  <div className="item">
                    <img
                      src={SteveImage}
                      alt="image"
                      className="rounded-circle w-20 mx-auto mb-4"
                    />
                    <h4 className="mb-1">Steve Shaw</h4>
                  </div>

                  <div className="item">
                    <img
                      src={GabrielaImage}
                      alt="image"
                      className="rounded-circle w-20 mx-auto mb-4"
                    />
                    <h4 className="mb-1">Gabriela Mataeescu</h4>
                  </div>

                  <div className="item">
                    <img
                      src={BrianImage}
                      alt="image"
                      className="rounded-circle w-20 mx-auto mb-4"
                    />
                    <h4 className="mb-1">Brian Archdekin</h4>
                  </div>
                </OwlCarousel>
              ) : (
                ""
              )}
            </div>
          </div>
          <div className="row">
            <div className="col-lg-8 offset-lg-2">
              <h2
                className="text-center mb-3"
                onClick={handleClickMemorium}
                style={{ cursor: "pointer" }}
              >
                In Memoriam - Jeff Lloyd
              </h2>
              <div hidden={showMemorium ? false : true}>
                <div style={{ display: "flex", justifyContent: "center" }}>
                  <img
                    className="rounded-circle w-20 mx-auto mb-4"
                    src={JeffImage}
                    alt=""
                  />
                </div>
                <p>
                  On April 27, 2021, our CTO Jeff Lloyd passed away. Jeff had
                  been battling cancer since an initial diagnosis in July 2020.
                  In the ten months since then he was a hard-working, thoughtful
                  and professional CTO and colleague, caring deeply about the
                  team and the company. Even when dealing with the side effects
                  of the cancer treatments he was constantly investing time in
                  planning and research, coaching team members and overseeing
                  the evolution of the product to an institutional level of
                  quality. Outside of work Jeff had an array of interests, from
                  hockey to guitar to his love of motorcycles. He is survived by
                  his wife and two young sons. All of us at Migrations.ml are
                  thankful to have walked several miles of our journey with Jeff
                  and benefited from knowing him. Jeff was empathetic, caring
                  and genial. The world needs more people like Jeff, and now we
                  are down one. We encourage anyone reading this message to
                  invest the time in their daily lives to tell the people you
                  love how much they mean to you.
                </p>
              </div>
            </div>
          </div>
        </div>
      </section>
    </PageBase>
  );
};

export default TeamPage;

